import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card } from "../components/Card"
import CBN from "../assets/cbn.png"
import PK from "../assets/pk.png"
import JAGAN from "../assets/jagan.png"
import { useNavigate } from "react-router-dom";
import { Login } from './Login';
import title from '../assets/title.png'
import { db } from "../firebase"
import { doc, collection, query, getDoc, orderBy, updateDoc, increment, onSnapshot } from "firebase/firestore";
import GreetingMessage from './Greetings'
import {jwtDecode} from 'jwt-decode';


const images  = 
    {
      "PK":PK,
      "JAGAN":JAGAN,
      "CBN": CBN
    }

function Cutouts(props) {
  const {user, setShowLogin} = props
  const navigate = useNavigate();
  const [addCounter, setAddCounter] = useState(0);
  const [cutouts, setCutouts] = useState([])
  const coCollection = collection(db, "cutout");
  const activityCollection = collection(db, "acitivity");
  const [cutout, setCutout] = useState("");
  const [msg, setMsg] = useState()

// Create a query against the collection.

  
  useEffect(()=>{
    const getCutoutDocs = async() => {
      const q = query(coCollection, orderBy("votes", "desc"));
      // const querySnapshot = await getDocs(q)
      // console.log(" querySnapshot => ", querySnapshot.docs);
      // setCutouts( querySnapshot.docs?.map( (doc) =>  doc.data()));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
            cities.push(doc.data());
        });
        setCutouts(cities)
      });
    };
    setShowLogin(false)
    getCutoutDocs()
  }, [])

  useEffect(()=>{
    if(user && addCounter){
      setAddCounter(addCounter - 1)
      addCutoutAPI()
    }
  })

  useEffect(()=>{
    // console.log("cutouts", cutouts)
  },[cutouts])
  
  const addCutout = (coKey) => {
    if(user){
      addCutoutAPI(coKey)
      setShowLogin(false)
    } else {
      setShowLogin(true)
      setAddCounter(addCounter + 1)
    }
  }

  const addCutoutAPI = async (coKey) => {
      const formattedDate = getFormattedDate()
      const washingtonRef = doc(db, "cutout", coKey?.toLowerCase() || cutout.toLowerCase());

      const activityRef = doc(db, "main", 'co'+formattedDate);

      const activityDoc = await getDoc(activityRef);
      const activityData = activityDoc.data() || [];
      let emails = activityData.emails

      const userData = jwtDecode(user.credential);
      const email = userData.email 
      if(emails.indexOf(email) === -1){
        emails.push(email);
        await updateDoc(activityRef, {
          emails: emails,
        });
        await updateDoc(washingtonRef, {
          votes: increment(1)
        });
      } else {
        setMsg("Your daily voting limit crossed, you can again vote tomorrow")
      }
  }

  function getFormattedDate() {
    const today = new Date();
  
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
  
    const formattedDate = `${day}${month}${year}`;
    
    return Number(formattedDate);
  }
  
 
  return (
    <>
      
      <header style={{height:"60px", justifyContent:"center", display:"flex", alignItems:"center", color:"white", paddingTop: 8}}>
        {/* <h1 style={{ boxShadow: "rgb(240 125 125 / 25%) 12px 12px 16px 0px, rgb(150 222 255 / 30%) -8px -8px 12px 0px" , padding: "4px", borderRadius: "10px" }}> Cutout </h1> */}
        <img alt={"Cutout"} style={{ boxShadow: "rgb(240 125 125 / 25%) 12px 12px 16px 0px, rgb(150 222 255 / 30%) -8px -8px 12px 0px" , padding: "4px", borderRadius: "10px" , height:"90%"}} src={title} />
      </header>
      <GreetingMessage />
      {msg && <div style={{color: 'red', padding:"8px 8px", marginTop: 8, border:"1px solid red"}}> You have reached the daily voting limit, you can poll again tomorrow </div>}
      <Row  gap={3} style={{backgroundColor: "black",  display: "flex", alignItems:"flex-end", "height":"50vh", textAlign: "center", marginBottom: 24}}>
        {cutouts.map((leader, idx)=>{
          return <Col xs={idx === 0 ? 5 : 5 - idx}> <Card image={images[leader.image]} order={idx} love={leader.votes} actionLabel={"Add"} cardAction={addCutout} coKey={leader.image} color={leader.color} setCutout={setCutout} smallerText={idx === 2 ? true : false} /> </Col>
        })}
      </Row>
      <div style={{background:'#242325', margin:"16px 0px 0px",padding:8}}>
        <Row style={{justifyContent:"center", fontWeight:700, color:"white",  paddingTop:4}}>
          {cutouts?.[0]?.name}
        </Row>
        
        <Row style={{color:cutouts?.[0]?.color, justifyContent:"center", fontWeight:500, paddingTop:4}} >
          {cutouts?.[0]?.party}
        </Row> 
      </div>
      
      
    </>
  );
}

export default Cutouts;
