import { Button, Image} from 'react-bootstrap';
import { ReactComponent as PlusIcon } from "../../assets/icons8-plus.svg"

export const Card = ({
    coKey,
    image,
    love,
    actionLabel,
    order,
    cardAction,
    setCutout, 
    color,
    smallerText
}) => {
    const styleProps = [
        {
            rowHeight: "40vh",
            colOneSpread:6,
            colTwoSpread:6,
        },
        {
            rowHeight: "30vh",
            colOneSpread:4,
            colTwoSpread:7
        },
        {
            rowHeight: "20vh",
            colOneSpread:4,
            colTwoSpread:7,
        },
    ]
    const handleBtnClick = (coKey) => {
        // console.log("Passing key", coKey)
        cardAction(coKey) 
        setCutout(coKey)
    }
    return (
        <>
        {/* <Row style={{height: styleProps[order]?.rowHeight, "display": "flex", "justify-content": "center", "align-items": "center", }}>
            <Col xs={styleProps[order]?.colOneSpread}> */}
                <Image style={{maxHeight:styleProps[order]?.rowHeight, maxWidth:"100%",boxShadow: "rgb(240 125 125 / 25%) 12px 12px 16px 0px, rgb(150 222 255 / 30%) -8px -8px 12px 0px" , marginBottom: "4px"}} src={image} rounded />
                <div style={{color:"white", margin: "0px 0px 16px", fontWeight:'bold', fontSize:'larger'}}>{love}</div>
                <div>
                    <span variant="light" style={{height:"25px", padding:"8px 18px", color:"white", border:`1px solid ${color}`, borderRadius:8, cursor:'pointer', fontSize: smallerText ? 'smaller' : 'inherit'  }} onClick={()=> handleBtnClick(coKey)}>
                        {actionLabel}
                    </span>
                </div>
                
            {/* </Col>
            <Col xs={styleProps[order]?.colTwoSpread}>
                <div style={{fontWeight:700, paddingLeft:"-50px"}}>{name}</div>
                <div>{party}</div>
                <div>{love}</div>
                <Button variant="primary" style={{right:"18%", position:"absolute"}}>{actionLabel}</Button>
            </Col>
        </Row> */}
        {/*<Row style={{height: styleProps[order]?.rowHeight, "display": "flex", "justify-content": "center", "align-items": "center", }}>
            <Col xs={styleProps[order]?.colOneSpre
                d}>
                <Image style={{maxHeight:"100%", maxWidth:"100%", "box-shadow": "12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3)"}} src={image} rounded />
            </Col>
            <Col xs={styleProps[order]?.colTwoSpread}>
                <div style={{fontWeight:700, paddingLeft:"-50px"}}>{name}</div>
                <div>{party}</div>
                <div>{love}</div>
                <Button variant="primary" style={{right:"18%", position:"absolute"}}>{actionLabel}</Button>
            </Col>
        </Row>*/}
        </>
    )
    
}