import {useState} from "react"
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import RequireAuth from "../components/RequireAuth"

export const Login = ({onLogin, user}) => {
    const {phone, setPhone} = useState("");
    const navigate = useNavigate();
    return <div style={{"display":"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
         <GoogleLogin
            onSuccess={credentialResponse => {
              onLogin?.(credentialResponse)
              // console.log("on login success", credentialResponse)
              if(credentialResponse?.credential){
                navigate('/home')
              }
            }}
          
            onError={() => {
              // console.log('Login Failed');
            }}
          
          />
          <br/>
        {/* <div style={{color:"white"}}>
            <Form.Label htmlFor="inputPassword5">Phone Number</Form.Label>
            <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                dark
            />
        </div> */}
    </div>
}