import { useState, useContext } from 'react';
import './App.css';
import { Container } from 'react-bootstrap';
import CBN from "./assets/cbn.png"
import PK from "./assets/pk.png"
import JAGAN from "./assets/jagan.png"
import {Login} from "./pages/Login"
import Home from "./pages/Home"
// Bringing in the GoogleOAuthProvider from the package
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Routes } from 'react-router-dom';
import { AuthContext } from './context/Auth';

function App() {
  const [user, setUser] = useState();
  const onLogin = (creds) => {
    // console.log("on login", creds)
    setUser(creds)
  }
  // const providerValue = () => {
  //   return {
  //     user: user
  //   }
  // }
  return (
  
    <GoogleOAuthProvider clientId="527524707527-og0rnrudedv713sq0kbc3b91ckmok0sl.apps.googleusercontent.com">
     
        <Container style={{background:"black", height:"100vh"}}>
          <Routes>
            <Route path='/*' element={<Home onLogin={onLogin} user={user}/>} />
            <Route path='/login' element={<Login onLogin={onLogin} user={user}/>} />
          </Routes>
        </Container>
     
    </GoogleOAuthProvider>
      
    
  );
}



export default App;
