// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_KEY,
  authDomain: "cutout-409313.firebaseapp.com",
  projectId: "cutout-409313",
  storageBucket: "cutout-409313.appspot.com",
  messagingSenderId: "527524707527",
  appId: "1:527524707527:web:d30ccea9d6ade3583792e2",
  measurementId: "G-DJTVCH8TNR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// const analytics = getAnalytics(app);