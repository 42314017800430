import {useState} from 'react';
import './Greetings.css'; // Import CSS for styling

const GreetingMessage = ({}) => {
    const [display, setDisplay] = useState(true)
    return (
        <>
            {display && <div className="greeting-container">
            <h3>Welcome to The Leader in You!</h3>
            <p>
                Thank you for joining us in shaping the future of public opinion. Your voice matters, 
                and together we’re building a platform that promotes transparency and accountability.
            </p>
            <p>
                Let’s lead the change!
                <span variant="light" style={{float:'right', padding:"2px 18px", color:"white", border:`1px solid white`, cursor:'pointer' }} onClick={()=>{setDisplay(!display)}}>
                    Yes
                </span>
            </p>
            </div>}
        </>
    );
};

export default GreetingMessage;
