import { useState } from 'react';
import { Login } from './Login';
import Cutouts from './Cutouts';


function Home({onLogin, user}) {

  const [showLogin, setShowLogin] = useState(false);
 
  return (
    <>{ (!showLogin || user) ? <Cutouts user={user} setShowLogin={setShowLogin} /> :  <Login onLogin={onLogin}/>}
    </>
  );
}

export default Home;
